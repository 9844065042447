<template>
  <div>
    <b-row class="mb-1 ml-1">
      <b-button
        variant="primary"
        class=""
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="goBack"
      >Go Back</b-button>
    </b-row>

    <div class="vx-row">
      <b-modal
        v-model="preview_job"
        centered
        hide-footer
        title="Job Preview"
      >
        <list-event
          v-if="preview_job"
          class="w-full"
          :inherited-event-id="preview_event_id"
        />
      </b-modal>
    </div>
    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
      <b-card
        class="p-2"
      >
        <vue-good-table
          ref="gTable"
          theme="polar-bear"
          :pagination-options="{
            enabled: true,
            perPage: serverParams.perPage,
            perPageDropdown: [5, 10, 20, 50, 100],
            dropdownAllowAll: false,
          }"
          :is-loading.sync="isLoading"
          :total-rows="totalRecords"
          :columns="columns"
          :rows="all_pending_jobs"
          :search-options="{
            enabled: false
          }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"

          style-class="vgt-table condensed bordered"
          compact-mode
          mode="remote"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-selected-rows-change="externalSelectionChanged"
        >
          <!-- @on-selected-rows-change="onSelectionChange" -->

          <template
            slot="column-filter"
            slot-scope="props"
          >
            <span
              v-if="props.column.field === 'created_on'"
              class="mt-2"
            >
              <flat-pickr
                v-model="created_on_filter"
                :config="configPicker"
                class="vgt-input"
                placeholder="Filter Posted On"
                @input="(value) => updateFilters(props.column.field, value)"
              />
            </span>
          </template>

          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Status -->
            <span v-if="props.column.field === 'source_name'">

              <b-badge
                pill
                class="text-center"
                variant="warning"
              >{{
                job_source_types[props.row.source_name]
              }}</b-badge>
            </span>
            <span v-else-if="props.column.field === 'salary'">

              <p class="font-medium truncate my-auto">
                {{ props.row.min_salary }} - {{ props.row.max_salary }}
              </p>
            </span>

            <span v-else-if="props.column.field === 'location'">

              <b-badge
                v-if="props.row.working_location_type === 'remote_only'"
                pill
                variant="light-warning"
              >
                Fully Remote
              </b-badge>
              <p
                v-else
                class="font-medium truncate my-auto"
              >
                {{ props.row.working_location_address }},
                {{ props.row.working_location_postal_code }},
                {{ props.row.working_location_state }}, {{ props.row.working_location_country }}
              </p>
            </span>

            <span v-else-if="props.column.field === 'interview_type'">
              <p class="font-medium truncate my-auto">
                {{ 
                  props.row.interview_type === 'speed_interview' 
                    ? "Speed Interview" 
                    : props.row.interview_type === 'virtual'
                      ? 'Virtual' : 'Physical'
                }}
              </p>
            </span>

            <span v-else-if="props.column.field === 'actions'">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <b-dropdown-item @click="previewJob(props.row)">
                  <div
                    v-b-tooltip.hover.top="'Click here to edit this job.'"
                    class="d-flex w-full"
                  >
                    <feather-icon
                      icon="EditIcon"
                      class="mx-1"
                      svg-classes="w-5 h-5 hover:text-primary stroke-current"
                    />
                    <p>Edit Job</p>
                  </div>

                </b-dropdown-item>

                <b-dropdown-item @click="approveJob(props.row.id, 'approve')">
                  <div
                    v-b-tooltip.hover.top="'Click here to approve this job.'"
                    class="d-flex w-full"
                  >
                    <feather-icon
                      icon="PlusCircleIcon"
                      class="mx-1"
                      svg-classes="w-5 h-5 hover:text-primary stroke-current"
                    />
                    <p>Approve Job</p>
                  </div>
                </b-dropdown-item>

                <b-dropdown-item @click="approveJob(props.row.id, 'reject')">
                  <div
                    v-b-tooltip.hover.top="'Click here to reject this job.'"
                    class="d-flex"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      svg-classes="w-5 h-5 hover:text-danger stroke-current"
                      class="mx-1"
                    />
                    <p>Reject Job</p>
                  </div>
                </b-dropdown-item>

              </b-dropdown>
            </span>
            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <!-- <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['5','10','20', '50', '100']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template> -->
        </vue-good-table>

      </b-card>
    </b-overlay>
  </div>
</template>

<script>

import {
  BCard,

  BButton,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  VBModal,
  BBadge,
  BAvatar,
  BOverlay,
  VBTooltip,
  BFormCheckbox,
  BFormInvalidFeedback,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormSelect,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import flatPickr from 'vue-flatpickr-component'

import ListEvent from '../ListEvent.vue'

export default {
  components: {
    ListEvent,
    VueGoodTable,
    flatPickr,

    BDropdown,
    BDropdownItem,
    BCard,
    BFormSelect,
    BButton,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BModal,
    BBadge,
    BAvatar,
    BOverlay,
    BFormCheckbox,
    BFormInvalidFeedback,
    BPagination,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      selected_pending_jobs: [],
      itemsPerPage: 6,
      isMounted: false,
      event_id: null,
      preview_event_id: null,
      preview_job: false,
      all_pending_jobs: [],
      all_interview_types: {
        speed_interview: 'Virtual Speed Interview',
        virtual: 'Virtual Interview',
        physical: 'In-Person Interview',
        none: 'Not Specified',
      },
      job_source_types: {
        efc: 'efc',
        mcf: 'mcf',
        upload: 'Uploaded',
        curated: 'Suggested By The System',
      },

      pageLength: 5,
      dir: false,
      columns: [
        {
          label: 'Job ID',
          field: 'id',
          filterOptions: {
            enabled: true,
            placeholder: '',
            trigger: 'enter',
          },
        },
        {
          label: 'Job Position',
          field: 'position',
          filterOptions: {
            enabled: true,
            placeholder: '',
            trigger: 'enter',
          },
        },
        {
          label: 'Interview Type',
          field: 'interview_type',
          filterOptions: {
            enabled: true,
            placeholder: '',
            trigger: 'enter',
            filterDropdownItems: [
              { value: 'physical', text: 'Physical' },
              { value: 'speed_interview', text: 'Speed Interview' },
              { value: 'virtual', text: 'Virtual' },
            ],
          },
        },

        {
          label: 'Job Salary',
          field: 'salary',
          type: 'number',
          tdClass: 'text-left',
          thClass: 'text-left',
          filterOptions: {
            enabled: true,
            placeholder: '',
            trigger: 'enter',
          },
        },
        {
          label: 'Job Location',
          field: 'location',
          filterOptions: {
            enabled: true,
            placeholder: '',
            trigger: 'enter',
          },
        },
        {
          label: 'Company Name',
          field: 'company_name',
          filterOptions: {
            enabled: true,
            placeholder: '',
            trigger: 'enter',
          },
        },
        {
          label: 'Posted On',
          field: 'created_on',
          type: 'date',
          dateInputFormat: 'do MMM yyyy HH:mm', // expects 2018-03-16
          dateOutputFormat: 'do MMM yyyy HH:mm', // outputs Mar 16th 2018
          tdClass: 'text-left',
          thClass: 'text-left',
          filterOptions: {
            customFilter: true,
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
          // valueFormatter: params => this.$formatDate(params.value),
        },
        {
          label: 'Actions',
          field: 'actions',
          filterOptions: {
            enabled: false,
            placeholder: '',
          },
        },

      ],
      rows: [],
      searchTerm: '',

      isLoading: true,

      // vue-good-table
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {},
        page: 1,
        perPage: 10,
      },
      selectedRows: null,
      configPicker: {
        altInput: true,
        enableTime: false,
      },
      created_on_filter: null,
    }
  },

  computed: {
    currentJobPage() {
      if (this.isMounted) {
        if (this.$refs.participating_table) {
          return this.$refs.participating_table.currentx
        }
        return 1
      }
      return 0
    },

    queriedJobItems() {
      return this.$refs.participating_table
        ? this.$refs.participating_table.queriedResults.length
        : this.all_pending_jobs.length
    },
  },
  mounted() {
    try {
      const next = this.$children[0].$refs.btnclose
      next.$el.addEventListener('click', this.onClickCloseButton, false)
    } catch (error) {}

    this.isMounted = true
    this.event_id = this.$route.params.event_id
    this.getJobsPendingApproval()
  },

  methods: {
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
      this.getJobsPendingApproval()
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
    },
    onSortChange(params) {
      if (params[0]) {
        const fieldName = params[0].field
        const sortDetails = { }
        sortDetails[fieldName] = params[0].type
        this.updateParams({ sort: sortDetails })
      }
    },
    onColumnFilter(params) {
      this.updateParams(params)
    },
    onSelectionChange(params) {
      this.selectedRows = params.selectedRows
    },
    updateFilters(column, value) {
      const currentFilters = this.serverParams.columnFilters
      currentFilters[column] = value
      this.updateParams({ columnFilters: currentFilters })
    },

    goBack() {
      this.$router.go(-1)
    },

    externalSelectionChanged(params) {
      this.selected_pending_jobs = params.selectedRows
    },
    previewJob(item) {
      // this.preview_job = true;
      this.preview_event_id = `${item.id}_pending`
      window.location.href = `/events-manager/edit-event/${this.preview_event_id}`
    },

    getJobsPendingApproval() {
      this.isLoading = true

      const query = `?params=${JSON.stringify(this.serverParams)}`

      this.$http
        .get(`/api/pending-approval/${this.event_id}${query}`)
        .then(response => {
          this.all_pending_jobs = response.data.output
          this.totalRecords = response.data.totalRecords

          this.isLoading = false
        })
        .catch(error => {
          this.$handleErrorResponse(error)
        })

      this.isLoading = false
    },

    approveJob(job_id, status) {
      this.$http
        .put(`/api/approve-job/${job_id}/${status}`)
        .then(response => {
          const { success } = response.data

          if (success) {
            const idx = this.all_pending_jobs.findIndex(x => x.id === job_id)

            this.$toastSuccess('Job Approval', response.data.message)

            this.all_pending_jobs.splice(idx, 1)
          } else {
            this.$toastDanger('Job Approval', esponse.data.message)
          }
        })
        .catch(error => {
          this.$handleErrorResponse(error)
        })
    },
  },
}
</script>

<style lang="scss">

</style>
